import React, { useState } from "react"

// make a new context
const ThemeContext = React.createContext({
  headerHeight: 0,
  setHeaderHeight: () => {},
})

// create the provider
const ThemeProvider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <ThemeContext.Provider
      value={{
        setHeaderHeight: (value) => setHeaderHeight(value),
        headerHeight: headerHeight,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }

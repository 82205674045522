exports.components = {
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/CaseStudy.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-case-study-listing-page-jsx": () => import("./../../../src/templates/CaseStudyListingPage.jsx" /* webpackChunkName: "component---src-templates-case-study-listing-page-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-events-landing-page-jsx": () => import("./../../../src/templates/EventsLandingPage.jsx" /* webpackChunkName: "component---src-templates-events-landing-page-jsx" */),
  "component---src-templates-home-landing-page-jsx": () => import("./../../../src/templates/HomeLandingPage.jsx" /* webpackChunkName: "component---src-templates-home-landing-page-jsx" */),
  "component---src-templates-industries-jsx": () => import("./../../../src/templates/Industries.jsx" /* webpackChunkName: "component---src-templates-industries-jsx" */),
  "component---src-templates-news-and-insights-listing-jsx": () => import("./../../../src/templates/NewsAndInsightsListing.jsx" /* webpackChunkName: "component---src-templates-news-and-insights-listing-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-solutions-jsx": () => import("./../../../src/templates/Solutions.jsx" /* webpackChunkName: "component---src-templates-solutions-jsx" */)
}

